
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const phone = ref(null);
    const formData = ref({
      tag_name: "",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const page = ref<string>("");
    // const value = ref('');
    const registration = Yup.object().shape({
      tag_name: Yup.string().required().label("Tag Name"),
    });

    onMounted(() => {
      const data = route.params.id;
      if (data) {
        // getUser(data);
        // page.value = "Edit";
        setCurrentPageBreadcrumbs("Edit Tag", []);
      } else {
        // page.value = "Add";
        setCurrentPageBreadcrumbs("Add Tag", []);
      }
      // setCurrentPageBreadcrumbs("Add User", ["User"]);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        getUser(id);
        page.value = "Edit";
      } else {
        page.value = "Add";
      }
    });
    const getUser = (id) => {
      var request = {
        url: `tag/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          formData.value = {
            tag_name: data.data.tag_name,
          };
        }
      });
    };

    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      //   console.log('values',values);
      //   console.log('resetForm',resetForm);
      // var request = {
      //   url: 'profile/changePassword',
      //   data: {
      //     old_password: values.current_password,
      //     new_password: values.password,
      //     user_id: profile.value._id
      //   }
      // }
      // Dummy delay
      // setTimeout(() => {
      // Send login request
      if (values.tag_name.trim() == "") {
        Swal.fire({
          text: "Please fill all fields!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        loading.value = true;
        var id = route.params.id;
        var request = {
          url: `tag/${id}`,
          data: {
            tag_name: values.tag_name,
          },
        };
        store
          .dispatch(Actions.PUT, request)
          .then((data) => {
            if (data) {
              Swal.fire("Success", "Tag Update successfully!", "success");
              router.push({ name: "user-tags" });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      // }, 2000);
      // Swal.fire({
      //   text: "User added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "admin-user" });
      // });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      formData,
      page,
      getUser,
    };
  },
});
